import player from './player'
import table from './table'
import round from './round'
import rule from './rule'

import { generateComparator, shuffle } from '@/utils/functions'
import { SortDirection, ThreeSignal } from '@/utils/constraints'

const selectMapComparator = generateComparator(['weight', SortDirection.ASC], ['point', SortDirection.DESC])

function createPairsFromPlayers(players) {
  if(players.length <= 2) {
    const [west, east] = players
    if(west.plays.some(p => p.opponent === east.seq)
      || east.plays.some(p => p.opponent === west.seq)) {
      return false
    }
    return [[west, east]]
  }
  let array = players.concat()
  let west = array.shift()
  let selectMap = array.map((e, i) => ({
    index: i,
    point: e.point,
    weight: west.plays.filter(p => p.opponent === e.seq).length,
  })).sort(selectMapComparator)
  while(selectMap.length > 0) {
    let eastIdx = selectMap.shift().index
    let east = array[eastIdx]
    let pairs = createPairsFromPlayers(array.filter((e, i) => i !== eastIdx))
    if(Array.isArray(pairs)) {
      return [[west, east]].concat(pairs)
    }
  }
  return false
}

export default {
  namespaced: true,
  state: () => ({
    id: null,
    phrase: null,
    created: null,
    startTime: null,
    backups: [],
  }),
  getters: {
    leaderboard(state) {
      return state.player.list.map(p => {
        const win = p.plays.filter(e => e.result === state.rule.pointWin).length
        const draw = p.plays.filter(e => e.result === state.rule.pointDraw).length
        const lose = p.plays.filter(e => e.result === 0).length
        const point = p.plays.reduce((a, b) => a + b.result, 0)

        const matchWinRate = p.plays.map(e => e.result)
          .reduce((a, b) => a + b, 0) / (state.rule.pointWin * p.plays.length)

        const newobj = {
          point, win, lose, draw, matchWinRate
        }
        return {...p, ...newobj}
      })
      .map((p, idx, arr) => {
        const solkoff = p.plays.map(pl => {
          const s = arr.find(e => e.seq === pl.opponent)
          return s ? s.point : 0
        }).reduce((a, b) => a + b, 0)

        const opponents = p.plays.map(pl => arr.find(e => e.seq === pl.opponent))
        .filter(opp => opp)

        const oppNum = opponents.length || 1

        const oppMwRate = opponents.map(opp => Math.max(opp.matchWinRate, 1 / 3))
        .reduce((a, b) => a + b, 0) / oppNum

        return {...p, solkoff, oppMwRate}
      })
      .sort(generateComparator(
        ...state.rule.tieBreaksEnabled,
        ['seq', SortDirection.ASC],
      ))
    },
    generatePlayerMatchTables: (state, getters) => () => {
      let players = shuffle(getters.leaderboard)
      .filter(e => !e.isDropped)
      .sort(generateComparator(['point', SortDirection.DESC]))
      if(players.length % 2 != 0) {
        players.push({ seq: 0, name: "bye", point: 0, plays: [] })
      }
      let tables = []
      const pairs = createPairsFromPlayers(players)
      if(pairs) {
        pairs.forEach(p => tables.push({
          seq: tables.length + 1,
          players: p,
          result: {
            winner: undefined,
            isDraw: false,
          },
        }))
      }
      return tables
    },
    checkInThreeSignal(state) {
      return state.player.list.length === state.rule.maxPlayer ? ThreeSignal.OK
        : state.player.list.length >= state.rule.minPlayer
        && state.player.list.length <= state.rule.maxPlayer ? ThreeSignal.WARN
        : ThreeSignal.BAD
    },
  },
  mutations: {
    registerId(state, { id, phrase, created }) {
      state.id = id
      state.phrase = phrase
      state.created = new Date(created)
    },
    startEvent(state) {
      state.startTime = new Date()
    },
    setBackups(state, backups) {
      state.backups = backups
    },
    restoreData(state, { startTime }) {
      state.startTime = startTime
    },

    addPlay(state, payload) {
      let play = {
        seq: state.player.nextPlaySeq,
        players: payload.players,
        winner: payload.winner,
        isDraw: payload.isDraw,
      }
      play.players.map(e => state.player.list.find(p => p.seq === e))
          .forEach(p => p.plays.push({
            opponent: play.players.find(e => p.seq !== e),
            result:   play.isDraw           ? state.rule.pointDraw
                    : play.winner === p.seq ? state.rule.pointWin
                    : 0,
          }))
      state.player.playHistory.push(play)
      state.player.nextPlaySeq++
    },
  },
  actions: {
    makePlayerMatches({ commit, getters }) {
      let tables = getters.generatePlayerMatchTables()
      commit('clearTables')
      commit('addTables', { tables })
    },
    finishRound({ commit, state }) {
      commit('finishRound')
      state.table.list.forEach(t => commit('addPlay', {
        players: t.players.map(p => p.seq),
        winner: t.result.winner,
        isDraw: t.result.isDraw,
      }))
    },
    advanceRound({ commit, dispatch, state }) {
      if(state.round.count === 0) {
        commit('startEvent')
      }
      commit('advanceRound')
      dispatch('makePlayerMatches')
      commit('startRound')
    },
    // async initBackups({ commit }) {
    //   const countDelete = await db.events
    //   .where('startTime').below(new Date(Date.now() - BACKUP_EXPIRE_DUE)).delete()
    //   console.debug(`delete ${countDelete} record(s)`)
    //   const arr = await db.events.orderBy('id').reverse().limit(10).toArray()
    //   commit('setBackups', arr)
    //   return arr.length && arr[0]
    // },
    restoreData({ commit }, backup) {
      commit('restoreData', backup)
      if(!backup.rule) {
        commit('setDefaultRule')
      }
    },
    setEvent({ commit }, { id, phrase, created }) {
      commit('registerId', { id, phrase, created })
    }
  },
  modules: {
    player, table, round, rule
  },
}
