export default {
  state: () => ({
    nextseq: 1,
    list: [],
    nextPlaySeq: 1,
    playHistory: [],
  }),
  getters: {
  },
  mutations: {
    addPlayer(state, payload) {
      state.list.push({
        seq: state.nextseq,
        name: payload.name,
        plays: [],
        isDropped: false,
      })
      state.nextseq++
    },
    addPlayers(state, { list }) {
      for(const player of list) {
        state.list.push({
          seq: state.nextseq,
          name: player,
          plays: [],
          isDropped: false,
        })
        state.nextseq++
      }
    },
    renamePlayer(state, payload) {
      let player = state.list.find(p => p.seq == payload.seq)
      if(!player) {
        throw "invalid player seq: " + payload.seq
      }
      player.name = payload.name
    },
    deletePlayer(state, seq) {
      state.list = state.list.filter(e => e.seq != seq)
    },
    dropPlayer(state, seq) {
      let player = state.list.find(p => p.seq == seq)
      if(!player) {
        throw "invalid player seq: " + seq
      }
      player.isDropped = true
    },
    restoreData(state, { player }) {
      if(!player) return
      state.nextseq = player.nextseq
      state.list = player.list
      state.nextPlaySeq = player.nextPlaySeq
      state.playHistory = player.playHistory
    },
    reassignPlayers(state) {
      state.list = state.list.concat().sort((a, b) => a.seq - b.seq)
      .map((e, idx) => ({...e, seq: idx + 1 }))
      state.nextseq = state.list.length + 1
    },
  },
}
