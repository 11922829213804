<template>
  <div id="man">
    <div v-if="isLoading" class="container">
      <div class="spinner-border text-muted"></div>
    </div>
    <template v-else>
      <ul id="nav" class="nav nav-pills container my-3">
        <li class="nav-item">
          <router-link class="nav-link" active-class="active" to="./">{{ $t('label.home') }}</router-link>
        </li>
        <li class="nav-item">
          <router-link class="nav-link" active-class="active" to="tables">{{ $t('label.table') }}</router-link>
        </li>
        <li class="nav-item">
          <router-link class="nav-link" active-class="active" to="leaderboard">{{ $t('label.leaderboard') }}</router-link>
        </li>
      </ul>
      <router-view/>
    </template>
  </div>
</template>
<script>
import axios from 'axios'
import event from '@/store/event'

export default {
  data() {
    return {
      error: null,
      isLoading: false,
      eventSource: null
    }
  },
  computed: {
    isWaitingRefresh() {
      return this.$store.state.isWaitingRefresh
    },
    lang() {
      return this.$route.params.lang
    }
  },
  created() {
      this.isLoading = true
      this.startSseListen()
  },
  methods: {
    async fetchData(key) {
      return axios.get(process.env.VUE_APP_BACKEND_HOST + '/api/event/' + key)
      .then(resp => resp.data)
    },
    startSseListen() {
      const key = this.$route.params.name
      const eventSource = new EventSource(process.env.VUE_APP_BACKEND_HOST + '/api/event/' + key);
      this.eventSource = eventSource

      eventSource.onerror = () => {
        console.error("connection failure.")
        this.error = "SDM server connection failure."
        this.isLoading = false

        this.$toast.error(this.error)
      }
      if(this.$store.hasModule('event')) {
        this.$store.unregisterModule('event')
      }
      this.$store.registerModule('event', event)
      this.$store.dispatch('event/setEvent', { id: null, phrase: key })
      
      eventSource.onmessage = e => {
        this.$store.dispatch('event/restoreData', JSON.parse(e.data))
        this.isLoading = false
      }
    },
    beforeRouteLeave(to, from, next) {
      this.eventSource.close()
      next()
    },
  }
}
</script>
<style>
#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav .active:not(.router-link-exact-active) {
  background-color: transparent;
}

#nav .nav-link.router-link-exact-active {
  /* color: hsl(var(--primary-HS), 41%); */
  background-color: var(--primary-color);
  color: white;
}

</style>
<i18n>
{
  "en": {
    "message": {
      "doneRestore": "Restored!"
    }
  },
  "ja": {
    "message": {
      "doneRestore": "復元しました。"
    }
  }
}
</i18n>
