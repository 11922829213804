export default {
  state: () => ({
    list: [],
  }),
  getters: {
    processingCount(state) {
      return state.list.filter(t => !t.result || !t.result.isDraw && !t.result.winner).length
    },
  },
  mutations: {
    addTables(state, payload) {
      state.list.push(...payload.tables)
    },
    clearTables(state) {
      state.list = []
    },
    setTableResult(state, payload) {
      let table = state.list.find(e => e.seq === payload.tableSeq)
      table.result = {
        winner: payload.winner,
        isDraw: payload.isDraw,
      }
    },
    restoreData(state, { table }) {
      if(!table) return
      state.list = table.list
    },
  },
  actions: {
    saveTableResult({ commit }, result) {
      commit('setTableResult', result)
    }
  },
}