import { SortDirection } from './constraints'

function generateComparatorArgParser(arg) {
  if(typeof(arg) == 'string')
    return {
      key: arg,
      direction: SortDirection.ASC
    }

  if(Array.isArray(arg))
    return {
      key: arg[0],
      direction: arg[1]
    }

  if(typeof(arg) == 'object')
    return arg
}

export function generateComparator(...args) {
  const compareArgs = args.map(generateComparatorArgParser)
  return (a, b) => {
    for (const { key, direction } of compareArgs) {
      let sgn = NaN
      switch(direction) {
        case SortDirection.DESC:
          sgn = -1
          break
        case SortDirection.ASC:
        default:
          sgn = 1
          break
      }
      const val = sgn * (a[key] - b[key])
      if(val) return val
    }
    return 0
  }
}
/**
 * get a random integer between two numbers
 * @param {number} min start integer(rounded up, included)
 * @param {number} max end integer(rounded down, included)
 * @return random integer between `min` and `max`
 */
export function randomInt(min, max) {
  min = Math.ceil(min)
  max = Math.floor(max)
  return Math.floor(Math.random() * (max - min)) + min
}

/**
 * remove first element in place, to match callback condition
 * @param {Array} array array
 * @param {function} callback callback function
 * 
 * @return array
 */
export function removeFirstWhere(array, callback) {
  let idx = array.findIndex(callback)
  if(idx < 0) return undefined
  return array.splice(idx, 1)[0]
}

/**
 * Get shuffled array (not mutate an original array).
 * Elements in array are assigned random number by `Math.random()`.
 * @param {Array} array 
 * @return shuffled array
 */
export function shuffle(array) {
  const seeds = array.map((e, i) => ({
    seed: Math.random(),
    index: i
  }))
  .sort(generateComparator('seed'))
  return seeds.map(e => array[e.index])
}
