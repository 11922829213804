import { RoundStatus } from '@/utils/constraints.js'
export default {
  state: () => ({
    count: 0,
    status: RoundStatus.PENDING
  }),
  mutations: {
    advanceRound(state) {
      state.count++
    },
    startRound(state) {
      state.status = RoundStatus.RUNNING
    },
    finishRound(state) {
      state.status = RoundStatus.PENDING
    },
    restoreData(state, { round }) {
      if(!round) return
      state.count = round.count
      state.status = round.status
    },
  }
}
