import localStorage from '@/persistent/localstorage'
import { Tiebreak } from '@/utils/constraints'

const defaultRule = {
  pointWin: 3,
  pointDraw: 1,
  tableSize: 2,
  minPlayer: 4,
  maxPlayer: 8,
  maxRound: 3,
  tieBreaksEnabled: [ Tiebreak.point, Tiebreak.solkoff ],
  tieBreaksDisabled: [ Tiebreak.oppMwRate ],
}

try {
  const userDefault = JSON.parse(localStorage.getItem('defaultRules'))
  if(userDefault) {
    Object.assign(defaultRule, userDefault)
    console.debug('default rules are loaded')
    console.debug(defaultRule)
  }
} catch {
  console.warn('failure to get data from localStorage')
}

export default {
  state: () => ({ ...defaultRule }),
  getters: {
  },
  mutations: {
    setRule(state, payload) {
      state.maxPlayer = payload.maxPlayer
      state.maxRound = payload.maxRound
      state.pointWin = payload.pointWin
      state.pointDraw = payload.pointDraw
      state.tieBreaksEnabled = payload.tieBreaksEnabled
      state.tieBreaksDisabled = payload.tieBreaksDisabled
    },
    setDefaultRule(state) {
      state.maxPlayer = defaultRule.maxPlayer
      state.maxRound = defaultRule.maxRound
      state.pointWin = defaultRule.pointWin
      state.pointDraw = defaultRule.pointDraw
      state.tieBreaksEnabled = defaultRule.tieBreaksEnabled
      state.tieBreaksDisabled = defaultRule.tieBreaksDisabled
    },
    restoreData(state, { rule }) {
      if(!rule) return
      state.maxPlayer = rule.maxPlayer
      state.maxRound = rule.maxRound
      state.pointWin = rule.pointWin
      state.pointDraw = rule.pointDraw      
      state.tieBreaksEnabled = rule.tieBreaksEnabled.map(tb => tb.key in Tiebreak ? Tiebreak[tb.key] : tb)
      state.tieBreaksDisabled = rule.tieBreaksDisabled.map(tb => tb.key in Tiebreak ? Tiebreak[tb.key] : tb)
    },
  }
}